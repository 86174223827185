import Axios from "axios";
import token from "./setToken";

export default {
  /** データ取込 */
  // async inputMlitOffice(inputDate, inputFile) {
  //   let params = new FormData();
  //   params.append('inputDate', inputDate);
  //   params.append('inputFile', inputFile);

  //   try {
  //     // await token.checkToken();
  //     const res = await Axios.post('/api/MlitOfficeInput', params, token.setTokenToHeaderFile());
  //     return res;

  //   } catch (error) {
  //     console.error("国交省登録情報データを取込できません");
  //     throw error;
  //   }
  // },
  /** データ取込 */
  async inputMlitOffice(inputDate, inputFile) {
    let params = new FormData();
    params.append('inputDate', inputDate);
    params.append('inputFile', inputFile);

    try {
      // await token.checkToken();
      const res = await Axios.post('/api2/MlitInput/MlitOfficeInput', params, token.setTokenToHeaderFile());
      return res;

    } catch (error) {
      console.error("国交省登録情報データを取込できません");
      throw error;
    }
  },

  /**
   * データ更新
   */
  async setDataList(mode, mlitDataSid, inputDate, mlitOfficeList) {
    let params = new URLSearchParams();

    params.append('mode', mode);
    params.append('mlitDataSid', JSON.stringify(mlitDataSid));
    params.append('inputDate', JSON.stringify(inputDate));
    params.append('mlitOfficeList', JSON.stringify(mlitOfficeList));

    try {
      const res = await Axios.post('/api/MlitOffice', params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("国交省登録情報を設定できません[setDataList]");
      throw error;
    }
  },

  /**
   * データ検索
   */
  async search(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      const res = await Axios.post("/api/MlitOfficeList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("国交省事業所一覧を取得できません[search]");
      throw error;
    }
  },

  /**
   * データ削除
   */
  async delDataList(mlitDataSid) {
    try {
      const res = await Axios.delete('/api/MlitOffice/' + mlitDataSid, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("国交省登録情報を削除できません[delDataList]");
      throw error;
    }
  },

  /** 事業所データ作成 */
  async getMlitOfficeData(mlitDataSid) {
    let params = new URLSearchParams();
    params.append('mlitDataSid', mlitDataSid);

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/MlitOfficeGetData", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("事業所データを作成できません[getMlitOfficeData]");
      throw error;
    }
  },

  /** Workテーブル登録 */
  async registMlitWorkTable(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/MlitWorkTableRegist", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("Workテーブルを作成できません[registMlitWorkTable]");
      throw error;
    }
  },

  /** 倉庫登録情報作成 */
  async getMlitWarehouseInfo(inputDateRMD, type) {
    let params = new URLSearchParams();
    params.append('inputDateRMD', inputDateRMD);
    params.append('type', type);

    try {
      const res = await Axios.post("/api/MlitWarehouseInfo", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("倉庫登録情報を作成できません[getMlitOfficeData]");
      throw error;
    }
  },

  /** 築年数資料作成 */
  async getMlitWarehouseDB(inputDateRMD, fileNameRMD, type) {
    let params = new URLSearchParams();
    params.append('inputDateRMD', inputDateRMD);
    params.append('fileNameRMD', fileNameRMD);
    params.append('type', type);

    try {
      const res = await Axios.post("/api/MlitWarehouseDB", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("築年数資料を作成できません[getMlitOfficeData]");
      throw error;
    }
  },

  /** 築年数ランキング(国交省版) */
  // async getMlitInstallation(inputDate, type) {
  //   let params = new URLSearchParams();
  //   params.append('inputDate', inputDate);
  //   params.append('type', type);

  //   try {
  //     const res = await Axios.post("/api/MlitInstallation", params, token.setTokenToHeader());
  //     return res;
  //   } catch (error) {
  //     console.error("築年数ランキング(国交省版)を作成できません[getMlitOfficeData]");
  //     throw error;
  //   }
  // },
  /** 築年数ランキング(国交省版) */
  async getMlitInstallation(inputDate, type) {
    const arrayStr = inputDate.split('-');
    let params = new URLSearchParams();
    params.append('nendo', arrayStr[0]);
    params.append('tsuki', arrayStr[1]);
    params.append('type', type);

    try {
      const res = await Axios.post("/api2/MlitCreateDocument/Installation", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("築年数ランキング(国交省版)を作成できません[getMlitOfficeData]");
      throw error;
    }
  },

  /** 築年数ランキング */
  async getMlitInstallationRank(inputDate) {
    const arrayStr = inputDate.split('-');
    let params = new URLSearchParams();
    params.append('nendo', arrayStr[0]);
    params.append('tsuki', arrayStr[1]);

    try {
      const res = await Axios.post("/api2/MlitCreateDocument/InstallationRank", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("築年数ランキングを作成できません[getMlitInstallationRank]");
      throw error;
    }
  },

  /** 国交省倉庫一覧 */
  // async getMlitWarehouseList() {
  //   let params = new URLSearchParams();

  //   try {
  //     const res = await Axios.post("/api/MlitWarehouseList", params, token.setTokenToHeader());
  //     return res;
  //   } catch (error) {
  //     console.error("国交省倉庫一覧を作成できません[getMlitOfficeData]");
  //     throw error;
  //   }
  // },
  /** 国交省倉庫一覧 */
  async getMlitWarehouseList() {
    //let params = new URLSearchParams();

    try {
      const res = await Axios.get("/api2/MlitCreateDocument/WarehouseList", token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("国交省倉庫一覧を作成できません[getMlitOfficeData]");
      throw error;
    }
  },

  /** 前年度より変更点 */
  // async getMlitDifference() {
  //   let params = new URLSearchParams();

  //   try {
  //     const res = await Axios.post("/api/MlitDifference", params, token.setTokenToHeader());
  //     return res;
  //   } catch (error) {
  //     console.error("前年度より変更点を作成できません[getMlitDifference]");
  //     throw error;
  //   }
  // },
  async getMlitDifference() {
    // let params = new URLSearchParams();

    try {
      const res = await Axios.get("/api2/MlitCreateDocument/Difference", token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("前年度より変更点を作成できません[getMlitDifference]");
      throw error;
    }
  },
};
